import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';

import { accountStateSelector } from 'state/account/account.selectors';
import { accountFetchPaymentHistoryRoutine } from 'state/account/account.routines';

import BirdiAccordion from 'ui-kit/accordion/accordion';
import PageSection from 'ui-kit/page-section/page-section';
import Pagination from 'components/pagination/pagination';
import LoadingMessage from 'ui-kit/loading-message/loading-message';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import PaymentHistoryInfo from 'components/payment-history-info/payment-history-info';
import './payment-history.style.scss';
import AccountBalance from 'components/account-balance/account-balance.component';

const PAGE_SIZE = 10;

const PaymentHistory = ({ data }: { data: GatsbyTypes.PaymentHistoryDataQuery }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { paymentHistory } = useSelector(accountStateSelector);
    const [currentPage, setCurrentPage] = useState(1);
    const [paymentsFetched, setPaymentsFetched] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const paymentHistoryRef = useRef();

    const hasNextPage = useMemo(() => paymentHistory && paymentHistory.length === PAGE_SIZE, [paymentHistory]);

    const paymentLines = useMemo(() => {
        if (paymentsFetched && paymentHistory) {
            return paymentHistory;
        } else {
            return null;
        }
    }, [paymentHistory, paymentsFetched]);

    const showLoading = paymentLines === null;

    const scrollToPaymentHistoryTop = () => {
        if (window && paymentHistoryRef && paymentHistoryRef.current) {
            window.scrollTo({
                top: window.scrollY + paymentHistoryRef.current.getBoundingClientRect().top - 250,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleNextPageClick = () => {
        setCurrentPage(currentPage + 1);
        scrollToPaymentHistoryTop();
    };
    const handlePrevPageClick = () => {
        const nextPage = currentPage - 1;
        setCurrentPage(nextPage < 1 ? 1 : nextPage);
        scrollToPaymentHistoryTop();
    };

    const fetchPaymentsHistory = useCallback(() => {
        // Reset the error and fetched state in case we're moving between pages.
        setFetchError(false);
        setPaymentsFetched(false);

        // Fetch the payment history lines.
        dispatch(
            accountFetchPaymentHistoryRoutine.trigger({
                page: currentPage.toString(),
                pageSize: PAGE_SIZE,
                includeAging: 'false',
                onSuccess: () => setPaymentsFetched(true),
                onFailure: () => setFetchError(true)
            })
        );
    }, [dispatch, currentPage]);

    useEffect(() => {
        fetchPaymentsHistory();
    }, [dispatch, currentPage, fetchPaymentsHistory]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.paymentHistory.eyebrowText`)}
            title={t(`pages.profile.paymentHistory.title`)}
            suppressChildrenContainer={true}
        >
            <AccountBalance variant="form" />
            <div className="profile-active-menu-item-wrapper">
                <div className="profile-active-menu-item-top-wrapper">
                    <Container fluid>
                        <Row>
                            <Col className="d-flex flex-column">
                                <h2 className="h3 profile-active-menu-heading">
                                    {t(`pages.profile.paymentHistory.heading`)}
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="profile-active-menu-item-bottom-wrapper">
                    <PageSection className="px-0">
                        <div className="payment-history" ref={paymentHistoryRef}>
                            <BirdiAccordion className="payment-history-accordion">
                                <>
                                    {fetchError && (
                                        <div className="h5 d-flex justify-content-center mb-5">
                                            {t('pages.profile.paymentHistory.messages.fetchError')}
                                        </div>
                                    )}
                                    {!fetchError && (
                                        <>
                                            <LoadingMessage
                                                className="mb-5"
                                                isVisible={showLoading}
                                                text={t(
                                                    'pages.profile.paymentHistory.messages.retrievingPaymentHistory'
                                                )}
                                            />
                                            {paymentLines &&
                                                paymentLines.length > 0 &&
                                                paymentLines.map((paymentLine, i) => {
                                                    return (
                                                        <PaymentHistoryInfo
                                                            key={`phi-${i}`}
                                                            payment={paymentLine}
                                                            translation={t}
                                                        />
                                                    );
                                                })}
                                            {paymentLines && paymentLines.length === 0 && (
                                                <div className="h5 d-flex justify-content-center mb-5">
                                                    {t('pages.profile.paymentHistory.messages.noPaymentHistory')}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            </BirdiAccordion>
                            <Pagination
                                currentPage={currentPage}
                                isNextPageDisabled={!hasNextPage}
                                onNextPageClick={handleNextPageClick}
                                onPreviousPageClick={handlePrevPageClick}
                            />
                        </div>
                    </PageSection>
                </div>
            </div>
        </ProfileLayout>
    );
};

export default PaymentHistory;

export const query = graphql`
    query PaymentHistoryData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
