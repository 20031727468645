import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Row, Col } from 'react-bootstrap';

import { PayBalanceFormValues } from 'types/balance';
import { PAY_BALANCE_SCHEMA } from 'schema/pay-balance';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import FormSelect from 'ui-kit/form-select/form-select';
import Link from 'ui-kit/link/link';

import { formatPrice } from 'schema/price.schema';
import { CreditCardPayload } from 'state/account/account.services';
import {
    accountFetchProfileRoutine,
    accountGetAllCreditCardsRoutine,
    accountPayBalanceRoutine
} from 'state/account/account.routines';
import { accountCreditCardsSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import PaymentForm from 'pages/secure/profile/intra-page-items/_payment.item';

const PayBalanceForm = ({
    currentBalance,
    submissionCallback,
    cancelCallback
}: {
    currentBalance: Number;
    submissionCallback?: Function;
    cancelCallback?: Function;
}): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formName = 'PayBalanceForm';
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const [isNewCard, setIsNewCard] = useState(false);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
    const [submitPaymentError, setSubmitPaymentError] = useState('');
    const [calculatedBalance, setCalculatedBalance] = useState('');

    const handleSubmit = (values: Partial<PayBalanceFormValues>, { setSubmitting }: { setSubmitting: Function }) => {
        setSubmitPaymentError('');
        dispatch(
            accountPayBalanceRoutine.trigger({
                currentBalance: currentBalance,
                cardSeqNum: values.cardSeqNum,
                locationId: '505',
                paymentAmount: values.paymentAmount,
                onSuccess: () => {
                    setSubmitting(false);
                    // Refresh the balance, by getting profile
                    dispatch(accountFetchProfileRoutine.trigger());
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: (
                                <BirdiModalContent
                                    title={t('payBalanceForm.successModal.title')}
                                    body={t('payBalanceForm.successModal.successMessage')}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('payBalanceForm.successModal.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        if (submissionCallback) {
                                            submissionCallback();
                                        }
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: (e) => {
                    setSubmitting(false);
                    setSubmitPaymentError(
                        e.ErrorMessage
                            ? e.ErrorMessage
                            : e.SystemMessage
                            ? e.SystemMessage
                            : t('payBalanceForm.unkownError')
                    );
                }
            })
        );
    };

    const handleCancelPayment = (formik) => {
        setIsNewCard(false);
        setSubmitPaymentError('');
        if (cancelCallback) {
            cancelCallback();
        }
    };

    const handleAddPaymentFormCancel = () => {
        dispatch(closeModal({}));
    };

    const handleAddNewPaymentClick = () => {
        dispatch(
            openModal({
                showClose: true,
                contentClassName: 'prescription-add-payment-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addPaymentModal.title')}
                        body={
                            <PaymentForm
                                centerFormSubmit={true}
                                isInModal={true}
                                onCancel={handleAddPaymentFormCancel}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const updatePaymentMethodOptions = () => {
        let paymentOptions = [];
        let defaultCSN = undefined;
        if (allPaymentData) {
            allPaymentData.map((option: CreditCardPayload, i: number) => {
                if (option.cardActive && option.cardIsExpired !== true) {
                    if (option.defaultCard) {
                        if (!isNewCard) {
                            defaultCSN = option.cardSeqNum.toString();
                        }
                    }
                    const formattedOption = {
                        key: `payment-option-${i}`,
                        label: t(
                            option.defaultCard
                                ? 'payBalanceForm.labels.cardTypeAndNumberPrimary'
                                : 'payBalanceForm.labels.cardTypeAndNumber',
                            {
                                type: option.cardType,
                                number: option.secureCardNumber.substring(option.secureCardNumber.length - 4)
                            }
                        ),
                        value: option.cardSeqNum.toString()
                    };
                    paymentOptions.push(formattedOption);
                }
            });
        }
        if (
            paymentMethodOptions &&
            paymentMethodOptions.length > 0 &&
            paymentMethodOptions.length !== paymentOptions.length
        ) {
            const results = paymentOptions.filter(
                ({ value: id1 }) => !paymentMethodOptions.some(({ value: id2 }) => id2 === id1)
            );
            if (results && results.length > 0) {
                setIsNewCard(true);
                defaultCSN = results[0].value;
            }
        }

        setPaymentMethodOptions(paymentOptions);
        return defaultCSN;
    };

    useEffect(() => {
        // Always get latest cards
        dispatch(accountGetAllCreditCardsRoutine.trigger());
    }, []);

    return (
        <Formik<Partial<PayBalanceFormValues>>
            onSubmit={handleSubmit}
            validationSchema={PAY_BALANCE_SCHEMA}
            initialValues={{
                paymentAmount: undefined,
                currentBalance: currentBalance,
                cardSeqNum: undefined
            }}
        >
            {function RenderForm(formik: any) {
                const handlePaymentFocus = () => {
                    setCalculatedBalance('');
                };
                const handlePaymentBlur = () => {
                    if (formik.values.paymentAmount) {
                        setCalculatedBalance(formatPrice(currentBalance - formik.values.paymentAmount));
                    } else {
                        setCalculatedBalance('');
                    }
                };
                useEffect(() => {
                    const defaultCSN = updatePaymentMethodOptions();
                    if (defaultCSN) {
                        formik.setFieldValue('cardSeqNum', defaultCSN);
                    }
                }, [allPaymentData]);

                return (
                    <Form
                        id="pay-balance-form"
                        data-ga-form-name={formName}
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                    >
                        <Row>
                            <Col className="col-12 col-md-7">
                                <div className="h3">{t('payBalanceForm.title')}</div>
                                <p className="text-slate mb-4">{t('payBalanceForm.instructions')}</p>
                                <Text
                                    formik={formik}
                                    type="currency"
                                    name="paymentAmount"
                                    className="field-PaymentAmount"
                                    label={t('payBalanceForm.labels.paymentAmount')}
                                    onFocus={handlePaymentFocus}
                                    onBlur={handlePaymentBlur}
                                    onChange={formik.handleChange}
                                    touched={formik.touched.paymentAmount}
                                    value={formik.values?.paymentAmount}
                                    errors={
                                        formik.errors?.paymentAmount
                                            ? t(`payBalanceForm.errors.paymentAmount${formik.errors.paymentAmount}`)
                                            : undefined
                                    }
                                    showErrorsText={true}
                                />
                                <Text
                                    className={'d-none'}
                                    type={'hidden'}
                                    name="currentBalance"
                                    onChange={formik.handleChange}
                                    touched={true}
                                    value={formik.values?.currentBalance}
                                    defaultValue={formik.initialValues.currentBalance}
                                />

                                {!formik.errors.paymentAmount && calculatedBalance && (
                                    <p className="text-slate mb-2">
                                        {t('payBalanceForm.oncePaid')}{' '}
                                        <span className="text-ebony-clay font-weight-bold">{calculatedBalance}</span>
                                    </p>
                                )}
                                {!(formik.errors.paymentAmount && formik.touched.paymentAmount) &&
                                    !calculatedBalance && <p className="mb-2">&nbsp;</p>}
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-md-4 pb-md-2">
                            <Col className="col-12 col-md-7">
                                <Field
                                    name="cardSeqNum"
                                    options={paymentMethodOptions}
                                    className={'m-0'}
                                    component={FormSelect}
                                    placeholder={t('payBalanceForm.labels.paymentMethod')}
                                    touched={formik?.touched?.cardSeqNum}
                                    value={formik?.values?.cardSeqNum}
                                    errors={
                                        formik?.errors?.cardSeqNum
                                            ? t('forms.errorMessages.requiredField', {
                                                  label: t('payBalanceForm.labels.paymentMethod')
                                              })
                                            : undefined
                                    }
                                    defaultValue={formik?.initialValues.cardSeqNum}
                                    formikControlled={true}
                                />
                            </Col>
                            <Col className="col-12 col-md-5">
                                <Button
                                    className={'justify-content-center d-flex align-items-center text-left pl-0'}
                                    label={t('payBalanceForm.labels.addPaymentMethod')}
                                    type="button"
                                    variant={'text'}
                                    dataGAFormName={formName}
                                    onClick={() => {
                                        handleAddNewPaymentClick();
                                    }}
                                    plusIcon
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                {t('payBalanceForm.footerInstructions')}{' '}
                                <Link
                                    className="d-inline-block"
                                    to={'/secure/profile/payment'}
                                    label={t('payBalanceForm.labels.paymentMethods')}
                                    variant="footer"
                                    dataGALocation={formName}
                                />
                                {'.'}
                                {submitPaymentError && <p className="text-danger mt-3">{submitPaymentError}</p>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Button
                                    async
                                    className="sm-full"
                                    label={t('payBalanceForm.labels.submit')}
                                    disabled={!formik.dirty || (formik.dirty && !formik.isValid) || formik.isSubmitting}
                                    variant="primary"
                                    type="submit"
                                    dataGAFormName={formName}
                                    isBusy={formik.isSubmitting}
                                    dataGALocation="PayBalanceForm"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Button
                                    className="p-3"
                                    label={t('payBalanceForm.labels.cancel')}
                                    disabled={formik.isSubmitting}
                                    variant="text"
                                    dataGAFormName={formName}
                                    isBusy={formik.isSubmitting}
                                    onClick={() => {
                                        handleCancelPayment(formik);
                                    }}
                                    dataGALocation="PayBalanceForm"
                                />
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PayBalanceForm;
