import React from 'react';

interface DollarIconProps {
    color?: string;
    alt?: string;
}

const DollarIcon = ({ color, alt }: DollarIconProps) => {
    return (
        <svg height="100%" viewBox="0 0 48 48" width="100%" xmlns="http://www.w3.org/2000/svg">
            <g fill={color ? color : 'currentColor'}>
                <path d="M24.0015303,48 C10.7673277,48 0,37.2326723 0,23.9984697 C0,10.7673277 10.7673277,0 24.0015303,0 C37.235733,0 48.0030606,10.7673277 48.0030606,23.9984697 C48.0030606,37.2326723 37.235733,48 24.0015303,48 M24.0015303,3.07288146 C12.459861,3.07288146 3.07288146,12.4629216 3.07288146,23.9984697 C3.07288146,35.540139 12.459861,44.9271185 24.0015303,44.9271185 C35.5431996,44.9271185 44.9301792,35.540139 44.9301792,23.9984697 C44.9301792,12.4629216 35.5431996,3.07288146 24.0015303,3.07288146"></path>
                <path d="M24.5184722,25.4455398 C24.4297137,25.4455398 24.3378945,25.4363578 24.249136,25.4210546 C20.4049735,24.7446534 15.2967672,23.3490021 15.2967672,19.0549257 C15.2967672,15.568858 18.8868966,13.2274692 24.2277115,13.2274692 C29.1614615,13.2274692 32.2129184,15.9728623 32.9597143,18.5284958 C33.1984442,19.3426258 32.7332271,20.1934834 31.9160365,20.4322132 C31.1019065,20.6648218 30.2510489,20.2057259 30.0123191,19.3885354 C29.5593445,17.8551553 27.4291398,16.3003507 24.2277115,16.3003507 C20.833463,16.3003507 18.3696487,17.4603328 18.3696487,19.0549257 C18.3696487,19.6027801 18.3696487,21.2708283 24.7816872,22.3971434 C25.6172416,22.5440541 26.1742779,23.3398202 26.0273672,24.1753746 C25.8957597,24.9191099 25.2469043,25.4455398 24.5184722,25.4455398"></path>
                <path d="M24.8024995,34.7710004 C19.3025314,34.7710004 14.8829688,32.282701 14.2922655,28.8517248 C14.1484155,28.019231 14.7085124,27.2234649 15.5471275,27.0796149 C16.3673787,26.9418861 17.1753874,27.4989224 17.3192374,28.3314162 C17.5977555,29.9504942 20.5359689,31.698119 24.8024995,31.698119 C28.4722056,31.698119 30.6666837,30.2871644 30.6666837,28.9221195 C30.6666837,28.2120513 30.6666837,26.553185 24.2485239,25.4207486 C23.4129695,25.2738379 22.8559332,24.4780718 23.0028438,23.6425174 C23.1497545,22.8039023 23.9577632,22.2621692 24.781075,22.3968373 C28.9496652,23.1313907 33.7395651,24.5362239 33.7395651,28.9221195 C33.7395651,32.3653383 30.0637378,34.7710004 24.8024995,34.7710004"></path>
                <path d="M24.5157177,16.3024931 C23.6679207,16.3024931 22.9792769,15.6138494 22.9792769,14.7660524 L22.9792769,10.9494357 C22.9792769,10.1016387 23.6679207,9.41299496 24.5157177,9.41299496 C25.3635146,9.41299496 26.0521584,10.1016387 26.0521584,10.9494357 L26.0521584,14.7660524 C26.0521584,15.6138494 25.3635146,16.3024931 24.5157177,16.3024931"></path>
                <path d="M24.5157177,38.5909839 C23.6679207,38.5909839 22.9792769,37.9023401 22.9792769,37.0545431 L22.9792769,33.2379264 C22.9792769,32.3901294 23.6679207,31.7014857 24.5157177,31.7014857 C25.3635146,31.7014857 26.0521584,32.3901294 26.0521584,33.2379264 L26.0521584,37.0545431 C26.0521584,37.9023401 25.3635146,38.5909839 24.5157177,38.5909839"></path>
            </g>
            <title>{alt ? alt : 'icon/info'}</title>
        </svg>
    );
};

export default DollarIcon;
