import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { accountProfileSelector } from 'state/account/account.selectors';
import { AccountBalanceProps } from './account-balance.props';
import PageSection from 'ui-kit/page-section/page-section';
import Button from 'ui-kit/button/button';
import { formatPrice } from 'schema/price.schema';
import './account-balance.style.scss';
import PayBalanceForm from './pay-balance-form';
import { navigate } from 'gatsby';
import DollarIcon from 'ui-kit/icons/dollar-icon/dollar-icon';

const AccountBalance = ({ variant }: AccountBalanceProps): ReactElement => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (profileObject?.patientBalance) {
            setCurrentBalance(Number.parseFloat(profileObject.patientBalance));
        } else {
            setCurrentBalance(0);
        }
    }, [profileObject]);

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    if (currentBalance > 0) {
        switch (variant) {
            case 'form': {
                return (
                    <div className="account-balance">
                        <div className="profile-active-menu-item-wrapper mb-5">
                            <div className="profile-active-menu-item-top-wrapper">
                                <Container fluid>
                                    <Row>
                                        <Col className="d-flex flex-column">
                                            <h2 className="h3 profile-active-menu-heading">
                                                {t(`components.accountBalance.form.heading`)}
                                            </h2>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="profile-active-menu-item-bottom-wrapper px-0">
                                <div className="px-2">
                                    <PageSection className="px-md-5 mb-0">
                                        <>
                                            <Row>
                                                <Col>
                                                    <div className="h3">
                                                        {t(`components.accountBalance.form.balanceDue`, {
                                                            balance: formatPrice(currentBalance)
                                                        })}
                                                    </div>
                                                    <p className="text-slate mb-4">
                                                        {t(`components.accountBalance.form.outstandingBalance`)}
                                                    </p>
                                                    <div>
                                                        <Button
                                                            type={'button'}
                                                            className={`mt-2 ${showForm ? 'd-none' : ''}`}
                                                            label={t('components.accountBalance.form.labels.payNow')}
                                                            onClick={toggleForm}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    </PageSection>
                                </div>
                                <Row className="collapsable">
                                    <Col className={showForm ? 'h-auto' : 'h-0'}>
                                        <div className="border-top border-geyser mt-2">
                                            <div className="px-2">
                                                <PageSection className="px-md-5 pt-4 mb-0">
                                                    <div>
                                                        <PayBalanceForm
                                                            currentBalance={currentBalance}
                                                            submissionCallback={toggleForm}
                                                            cancelCallback={toggleForm}
                                                        />
                                                    </div>
                                                </PageSection>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                );
            }
            case 'banner': {
                return (
                    <div className="account-balance banner">
                        <div className="banner_wrapper text-center align-items-center">
                            <div className="text-cerulean">
                                <div className="balanceIcon">
                                    <DollarIcon />
                                </div>
                            </div>
                            <div className="banner__outstanding text-md-left">
                                <div className="banner__outstanding__desktop text-ebony-clay d-md-none">
                                    {t(`components.accountBalance.banner.outstandingBalanceMobile`)}
                                    <span className="balanceAmount font-weight-bold">
                                        {formatPrice(currentBalance)}
                                    </span>
                                </div>
                                <div className="banner__outstanding__mobile text-ebony-clay d-none d-md-block">
                                    {t(`components.accountBalance.banner.outstandingBalance.beforeAmount`)}
                                    <span className="balanceAmount font-weight-bold">
                                        {formatPrice(currentBalance)}
                                    </span>
                                    {t(`components.accountBalance.banner.outstandingBalance.afterAmount`)}
                                </div>
                            </div>
                            <div className="banner__button text-md-right sm-full">
                                <Button
                                    className="my-md-0 sm-full"
                                    label={t('components.accountBalance.banner.labels.payNow')}
                                    onClick={() => navigate('/secure/profile/payment/history')}
                                    type="button"
                                    dataGALocation="MedicineCabinet"
                                />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
    return <></>;
};

export default AccountBalance;
